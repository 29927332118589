import React from 'react';

import { useOrganizations } from 'hooks/useOrganizations';

/**
 * HOC that wraps a component which will use organizations
 * @param WrappedComponent
 * @returns
 */
export const withOrganizations = <P,>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    useOrganizations();

    return <WrappedComponent {...props} />;
  };
};
