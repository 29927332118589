import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Snack } from 'components/Snackbar/Snackbar.entity';
import { customSwitch } from 'utils/helpers';
import ApiRes from '../../services/ApiRes';
import { apiNotifications } from '../../services/apis/notifications';
import { SET_SNACKBAR } from '../errors/Errors.reducer';
import { AppDispatch, AppState } from '../store';
import { SET_NOTIFICATIONS } from './Notifications.reducers';

export const getMyNotifications = (): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    const res: ApiRes = await apiNotifications.getMyNotifications();

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_NOTIFICATIONS(res.data));
      },

      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });
  };
};

export const resolveNotifications = (ids: string[]): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    const res: ApiRes = await apiNotifications.resolveNotifications(ids);

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_NOTIFICATIONS(res.data));
      },

      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });
  };
};
export const clearNotifications = (): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    const res: ApiRes = await apiNotifications.clearNotifications();

    customSwitch(res.status, {
      204: () => {
        dispatch(SET_NOTIFICATIONS([]));
      },

      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });
  };
};
