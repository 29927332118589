import React from 'react';

import { useChartDisplay } from 'hooks/useChartDisplay';

/**
 * HOC that wraps a component which will use organizations
 * @param WrappedComponent
 * @returns
 */
export const withChartDisplayHandle = <P,>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    useChartDisplay();

    return <WrappedComponent {...props} />;
  };
};
