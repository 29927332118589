import React from 'react';

import { useSessionDecision } from 'hooks/useSessionDecision';

/**
 * HOC that wraps a component which will show a pop-up if user's session is more than sessionLength
 * @param WrappedComponent
 * @returns
 */
export const withSessionDecision = <P,>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    useSessionDecision();

    return <WrappedComponent {...props} />;
  };
};
