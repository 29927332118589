import { apiSdk } from '../activateApi';
import ApiRes from '../ApiRes';

export const apiNotifications = {
  getMyNotifications: async () => {
    return ApiRes.customFetch(() => apiSdk.getNotificationsMe());
  },
  resolveNotifications: async (ids: string[]) => {
    return ApiRes.customFetch(() => apiSdk.patchNotificationsResolve({}, { ids }));
  },
  clearNotifications: async () => {
    return ApiRes.customFetch(() => apiSdk.deleteNotificationsClear({}, {}));
  },
};
