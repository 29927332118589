import React from 'react';
import { Link } from '@material-ui/core';

import LinkButton from 'components/Button/LinkButton/LinkButton';
import { upFChar } from 'utils/textFormatting';

import styles from './NotFound.module.scss';

export interface ExternalNotFoundProps {
  error: 404 | 500 | 403;
}

interface NotFoundProps extends ExternalNotFoundProps {
  t: (tranlation: string) => string;
}

const NotFound: React.FC<NotFoundProps> = ({ t, error }) => {
  return (
    <div className={styles.NotFoundContainer}>
      <Link href={'/documents'}>
        <img src="/assets/logo_SECBIO.svg" alt="logo" className={styles.NotFoundLogo} />
      </Link>
      <h1 className={styles.NotFoundTitle}>
        {upFChar(t('pages.notFound.error'))} {error}
      </h1>
      <h2 className={styles.NotFoundSubTitle}>{upFChar(t(`pages.notFound.${error}.title`))}</h2>
      <div>
        <LinkButton link={'/documents'} type={'fill'} color={'primary'}>
          {upFChar(t('pages.notFound.toHome'))}
        </LinkButton>
      </div>
    </div>
  );
};

export default NotFound;
