import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizations } from 'store/organizations/Organizations.actions';
import { AppState } from 'store/reducers';

/**
 * Handles acquiring organizations
 * @param searchablesKeys: [key] or [[key, subkey]]
 * @returns handleSearch, searchChar, dataForTable, handleSetDataBeforeSearch
 */

export function useOrganizations() {
  const organizations = useSelector((state: AppState) => state.organizationsReducer.organizations);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!organizations || organizations.length === 0) {
      dispatch(getOrganizations({ page: 0 }));
    }
  }, [dispatch, organizations]);
}
