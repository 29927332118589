import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { sessionLength, timeBeforeForceLogout } from 'config/config';
import { setModalContent } from 'store/modals/Modals.actions';
import { logOut } from 'store/user/User.actions';
import { upFChar } from 'utils/textFormatting';

export const useSessionDecision = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const lastRefreshSessionTokenDate = Cookies.get('lastRefreshSessionTokenDate');

    const dateObject = new Date(parseInt(lastRefreshSessionTokenDate));

    let remainingTime = sessionLength - (new Date().getTime() - dateObject.getTime());

    if (remainingTime < -timeBeforeForceLogout) dispatch(logOut());
    if (remainingTime < 0) remainingTime = 0;

    setTimeout(() => {
      dispatch(
        setModalContent({
          properties: {
            size: 'little',
            type: 'sessionDecision',
            cantClickOutside: true,
          },
          header: {
            title: upFChar(t(`components.modals.header.titles.sessionDecision`)),
          },
        }),
      );
    }, remainingTime);
  }, [dispatch, t]);
};
