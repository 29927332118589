import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setModalContent } from 'store/modals/Modals.actions';
import { AppState } from 'store/reducers';
import { upFChar } from 'utils/textFormatting';

export function useChartDisplay() {
  const currentUser = useSelector((state: AppState) => state.userReducer.userData);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser && currentUser !== null && !currentUser.hasAcceptedChart) {
      dispatch(
        setModalContent({
          properties: {
            size: 'large',
            type: 'chart',
            cantClickOutside: true,
          },
          header: {
            title: upFChar(t(`components.modals.header.titles.chart`)),
          },
        }),
      );
    }
  }, [dispatch, currentUser, t]);
}
