import dynamic from 'next/dynamic';
import router, { withRouter } from 'next/router';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withChartDisplayHandle } from 'hoc/withChartDisplayHandle';
import { withOrganizations } from 'hoc/withOrganizations';
import { withRestoreUserSession } from 'hoc/withRestoreUserSession';
import { withSessionAuthContext } from 'hoc/withSessionAuth';
import { withSessionDecision } from 'hoc/withSessionDecision';
import { ModalsReducer } from 'store/modals/Modals.model';
import { getMyNotifications } from 'store/notifications/Notifications.actions';
import { NotificationsReducer } from 'store/notifications/Notifications.model';
import { AppState } from 'store/store';
import { ITemplateMainProps } from './TemplateMain.component';

export const TemplateMainWithNoSSR = dynamic<ITemplateMainProps>(import('./TemplateMain.component') as any, {
  ssr: false,
});

export interface ITemplateMainState {
  displayChangeVersion: ModalsReducer['displayChangeVersion'];
  modalContent: ModalsReducer['content'];
  notifications: NotificationsReducer['notifications'];
}
export interface ITemplateMainActions {
  getMyNotifications: typeof getMyNotifications;
}
const mapStateToProps = (state: AppState): ITemplateMainState => ({
  displayChangeVersion: state.modalsReducer.displayChangeVersion,
  modalContent: state.modalsReducer.content,
  notifications: state.notificationsReducer.notifications,
});

const actionCreators: ITemplateMainActions = { getMyNotifications };
const enhance = compose(
  withRouter,
  withTranslation(),
  withSessionDecision,
  connect(mapStateToProps, actionCreators),
  withSessionAuthContext({
    requireAuth: true,
    redirectToLogin: () => router.push('/'),
  }),
  withOrganizations,
  withRestoreUserSession,
  withChartDisplayHandle,
);

export default enhance(TemplateMainWithNoSSR) as React.ComponentType;
