import Link from 'next/link';

import styles from './LinkButton.module.scss';

interface LinkButtonProps {
  color: 'secondary' | 'primary';
  type: 'border' | 'fill';
  link: string;
  onClick?: () => void;
  openInNewTab?: boolean;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
  children,
  color,
  type,
  link,
  onClick,
  openInNewTab,
}) => {
  const mainColor = color === 'primary' ? 'var(--primary)' : 'var(--secondary)';

  return (
    <Link href={link} as={link}>
      <a
        href={link}
        onClick={onClick}
        target={openInNewTab ? '_blank' : undefined}
        className={[
          styles.container,
          type === 'fill' ? styles.fill : styles.border,
          color === 'primary' ? styles.hoverBlue : styles.hoverRed,
        ].join(' ')}
        style={{
          backgroundColor: type === 'fill' ? mainColor : 'var(--white)',
          borderColor: mainColor,
          color: type === 'fill' ? 'var(--white)' : mainColor,
        }}
        rel="noreferrer">
        <p>{children}</p>
      </a>
    </Link>
  );
};
export default LinkButton;
