import { withRouter } from 'next/router';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import NotFound, { ExternalNotFoundProps } from './NotFound.component';

const enhance = compose(withRouter, withTranslation());

export default enhance(NotFound) as React.ComponentType<ExternalNotFoundProps>;
